<template>
  <div class="bill">
    <header-bar :showNotice="false" :showBack="true" :transparent="true" @setLanguage="load"></header-bar>

    <div style="margin-top: -90px;">
      <title-name :title="$t('Bill.title')" label="Bill"></title-name>
    </div>

    <div class="list">
      <div class="list-name">
        <div>
          {{ $t('Bill.date') }}
        </div>
        <div class="justify-center">
          {{ $t('Bill.leixing') }}
          <img src="@/assets/img/base/xia.png" @click="showPicker = true" />
        </div>
        <div class="justify-center">{{ $t('Bill.coin') }}</div>
        <div class="justify-end">{{ $t('Bill.num') }}</div>
      </div>
      <van-list
        v-if="list.length > 0"
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('Bill.finished')"
        :immediate-check="false"
        offset="10"
        @load="onLoad"
      >
        <div class="list-val" v-for="item in list" :key="item.id">
          <div>{{ item.bdate }}</div>
          <div class="text-center">{{ item.bnames }}</div>
          <div class="text-center" v-if="item.cid==4">USDA</div>
          <div class="text-center" v-else-if="item.cid==5">CCAI</div>
          <div class="text-center" v-else>{{ item.cname }}</div>
          <div class="text-right">
            <span v-if="item.amount > 0">+</span>
            {{ item.amount }}
          </div>
        </div>
      </van-list>
      <empty :text="$t('Base.empty2')" v-if="list.length == 0" />
    </div>

    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker
        :columns="filterList"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar'
import TitleName from '@/components/TitleName'
import Empty from '@/components/Empty'
import { ref } from 'vue'

import { List, Popup, Picker } from "vant";

export default {
  name: 'Bill',
  components: {
    HeaderBar,
    TitleName,
    Empty,
    [List.name]: List,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  setup() {
    return {
      us: ref(null),
      pageIndex: ref(1),
      list: ref([]),
      allList: ref([]),
      loading: ref(false),
      finished: ref(false),
      showPicker: ref(false),
      filterList: ref([])
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.load()
    /* this.getdata() */
  },
  methods: {
    load() {
      this.filterList = [
        {text: this.$t('Bill.all'), val: 99},
        {text: this.$t('Bill.shouyi'), val: 0},
        {text: this.$t('Bill.chongzhi'), val: 1},
        {text: this.$t('Bill.tixian'), val: 2},
        {text: this.$t('Bill.zhuanzhang'), val: 3},
        {text: this.$t('Bill.zhuanhuan'), val: 4},
        {text: this.$t('Bill.zengjian'), val: 5},
        {text: this.$t('Bill.xiaofei'), val: 6}
      ]
      this.list = []
      /* this.allList = [] */
      this.getdata()
    },
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    back() {
      this.$router.back()
    },
    onConfirm(e) {
      if(e.val == 99) {
        this.list = this.allList
      } else {
        this.list = this.allList.filter(item => {
          return item.lx == e.val
        })
      }
      this.showPicker = false
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/Bill/List",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
          pageIndex: _this.pageIndex,
          pageSize: 10
        },
        (res) => {
          _this.loading = false
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data
          if(data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.list.push(...data)
          _this.allList.push(...data)
        }
      )
    }
  }
}
</script>

<style scoped>
.bill {
  min-height: 100vh;
  background-image: linear-gradient(to right top, #2d8998, #519bab, #6fadbe, #8cc0d1, #a8d3e4, #b1dbec, #bbe2f3, #c4eafb, #bde8fa, #b7e5fa, #b0e3f9, #a9e1f8);
}

.back-wrap {
  padding: 0 12px;
}

.list {
  margin: 24px 12px 0;
  padding: 0 10px;
}

.list-name {
  display: flex;
}

.list-name > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.list-name img {
  margin-left: 8px;
  width: 14px;
}

.list-val {
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.list-val > div {
  flex: 1;
}
</style>